/* eslint-disable */
import React from "react"
import { graphql } from 'gatsby'
import { css } from '@emotion/core'
import Layout from '../../components/theme/Layout'
import Footer from '../../components/theme/Footer'
import Header from '../../components/theme/Header'
import SmallImageHeader from '../../components/theme/SmallImageHeader'
import RenderBlock from '../../components/blocks/RenderBlock'

import CovidImg from '../../assets/images/Royale2.jpg'
import Logo1 from '../../assets/images/logo1@2x.png'
import Logo2 from '../../assets/images/logo2@2x.png'
import Logo3 from '../../assets/images/logo3@2x.png'
import ImgRight from '../../assets/images/imgright@2x.png'
import Button from '../../components/common/Button'
import CovidDonation from "../../components/common/CovidDonation";
import EventTracker from "../../components/common/EventTracker";
import Offers from "../../components/common/Offers";
import ImageRightTextLeft from "../../components/blocks/ImageRightTextLeftBlock";
import ImageLeftTextRight from "../../components/blocks/ImageLeftTextRightBlock";
import fixImageUrl from "../../helpers/fixImageUrl";

const AboutRoyale = ({ data }) => {

  // This is only for the Bianca response, remove after that
  const blocks = []
  const metas = data.defaultPage.meta
  const urls = data.defaultPage.urls
  for (let i = 2; i < data.defaultPage.blocks.length; i+=1) {
    blocks.push(data.defaultPage.blocks[i])
  }
  const updatedMessages = {
    title:'FièRRRement associée à Bianca Andreescu',
    description:'Bianca incarne toutes les qualités qui font notre fierté canadienne : force, dévouement, gentillesse et esprit sportif, aussi bien sur le terrain qu’en dehors. C’est la parfaite ambassadrice de la marque ROYALE<sup>MD</sup>.<br><br><strong>Bianca et ROYALE : FièRRRement canadiennes.</strong>'
  }
  urls.en = '/purr-oudlycanadian'
  urls.fr = '/fr/fierrrementcanadienne'
  metas.title = 'FièRRRement associée à Bianca Andreescu | RoyaleMD'

  let image1 = fixImageUrl("/globalassets/promotion/bianca/bianca-2.jpg");
  let image1Text = [
    {
      "__typename": "HeaderBlock",
      "type": "h2",
      "title": "Les points saillants de la carrière de Bianca Andreescu",
      "guid": "image1header"
    },
    {
      "__typename": "TextBlock",
      "text": "<ul><li>Championne en titre du tournoi de tennis du Canada</li><li>Première Canadienne à remporter le titre en 50 ans</li><li>Première joueuse de tennis canadienne à gagner un titre en simple du Grand Chelem</li><li>Championne du tournoi de tennis des États-Unis de 2019</li><li>Canadienne la mieux classée de l’histoire de l’Association de tennis féminin avec un quatrième rang en carrière</li><li>Première joueuse à remporter un titre du classement junior du Grand Chelem en simple depuis 2006.</li></ul>",
      "guid": "bianca1",
      "modification": "biancabullet"
    }
  ];

  let image2 = fixImageUrl("/globalassets/promotion/bianca/bianca-3.jpg");
  let image2Text = [
    {
      "__typename": "HeaderBlock",
      "type": "h2",
      "title": "Suivez son parcours @biancaandreescu_",
      "guid": "image2header"
    },
    {
      "__typename": "LinkBlock",
      "title": "Suivez son voyage",
      "link": "https://www.instagram.com/biancaandreescu_/",
      "modification": "",
      "guid": "image2button"
    }
  ];

  let image3 = fixImageUrl("/globalassets/proudly-plant.jpg");
  let image3Text = [
    {
      "__typename": "HeaderBlock",
      "type": "h2",
      "title": "Une compagnie fièRRRement canadienne",
      "guid": "image3header"
    },
    {
      "__typename": "TextBlock",
      "text": "Nous sommes une compagnie canadienne qui fabrique des produits de qualité, auxquels les utilisateurs font confiance pour se faciliter la vie au quotidien. Nous sommes ravis de faire partie d’une équipe aussi passionnée que diversifiée. Chaque jour, nous faisons face à la concurrence (et connaissons du succès) à l’échelle internationale.",
      "guid": "image3text"
    }
  ];
  // end Bianca adjustment

  return (
    <Layout
      meta={metas}
      urls={urls}
      locale='fr'
    >
      <Header
        urls={urls}
        locale='fr'
      />

      { /*  data.defaultPage.title / data.defaultPage.description / data.defaultPage.subtitle */}
      <SmallImageHeader
        subtitle=''
        description={updatedMessages.description}
        title={updatedMessages.title}
        image={false}
        video="https://vimeo.com/428576867"
        autoplay
        alt={data.defaultPage.title}
        right
      />

      { /*  Bianca Extra Section */}
      <ImageRightTextLeft alt="Bianca Andreescu" image={image1} left={image1Text} modification="center fullimage" />
      <ImageLeftTextRight alt="Suivez son parcours @biancaandreescu_" image={image2} right={image2Text} modification="biancablue center wider" />
      <ImageRightTextLeft alt="Une compagnie fièRRRement canadienne" image={image3} left={image3Text} caption="Faison – Opérateur, usine de Toronto" modification="center morepadding" />
      {/*
      <div className="flex-container pad-left pad-top">
        <div className="fourth">
          <h3>Notre contribution</h3>
          <div className="quote">
            <quote>
              « En tant que communauté, il est important que nous prenions soin de nos voisins qui sont touchés. »
              <span> - Robert K. Irving, Co-PDG de J.D. Irving, Limited</span>
            </quote>
          </div>

          <p>L’organisme Food Depot Alimentaire prévoit que la demande du nombre de repas augmentera de 50 % au Nouveau-Brunswick. En raison de la fermeture des écoles et de l’annulation des programmes jeunesse, il faudra aussi remplacer le programme des petits-déjeuners et autres programmes alimentaires. Outre l’augmentation de la demande, de nombreuses banques alimentaires ne sont plus en mesure d’amasser des fonds. Les magasins d’occasion et autres commerces du genre ont dû fermer leurs portes, ce qui limite les possibilités pour les gens. Pour répondre à cette situation urgente, J.D. Irving, Limited fait un don de 1 million de dollars à Food Depot Alimentaire. Cet argent sera affecté au réseau des 60 banques alimentaires du Nouveau-Brunswick qui connaissent des difficultés financières en raison de l’épidémie de coronavirus (COVID-19). </p>
            <Button
              to='https://jdirving.com/BlogPage.aspx?id=6364&blogid=74&LangType=1036'
              width='170px'
              css={css`outline ${tw`mb-4`}`}
              outline
              convert={false}
              external
            >
            Tous les détails
            </Button>
        </div>
        <div className="sixth">
          <img src={ImgRight} alt="" />
          <small>De gauche à droite : Dale Hicks, président de Food Depot Alimentaire; Robert K. Irving, Co-PDG de J.D. Irving, Limited </small>
        </div>
      </div>
      <div className="flex-container blue-section pad-both">
        <div className="half">
          <h3>Nos mesures de sécurité à l’égard de la COVID-19</h3>
          <p>L’épidémie de coronavirus (COVID-19) a complètement chamboulé le monde où nous vivons, et les gouvernements ont déclaré que la production de produits de papier était un service essentiel durant cette période éprouvante. Nous faisons tout en notre pouvoir pour continuer à fabriquer les produits de papier ROYALE<sup>MD</sup> dont les Canadiens ont besoin pour assurer la propreté et la salubrité de leur foyer. La sécurité et le bien-être de nos employés et de nos collectivités demeurent notre priorité absolue.</p>
          <p>C’est pourquoi nous avons adopté des mesures pour protéger de notre mieux chacun de nos employés – notamment en encourageant le télétravail lorsque cela est possible et en réduisant les effectifs dans chacune des installations au nombre d’employés minimal nécessaire à l’exécution sécuritaire et fiable de nos activités. </p>
          <p>De plus, les employés dans tous nos secteurs d’activités respectent les mesures de santé et sécurité émises par les agences locales de santé publique afin de prévenir la propagation de la COVID-19 : </p>
        </div>
        <div className="half">
          <h3>&nbsp;</h3>
          <br />
          <ul>
            <li>De nouveaux protocoles ont été mis en place pour le dépistage des employés à l’entrée de nos installations.  </li>
            <li>Nous encourageons les réunions virtuelles pour les rencontres d’équipe et avec les clients. </li>
            <li>Nous avons cessé tous les voyages internationaux et à l’intérieur du Canada afin de limiter l’exposition potentielle au virus. </li>
            <li>Nous avons grandement renforcé les protocoles de nettoyage et de désinfection dans nos installations. </li>
            <li>Nous offrons jusqu’à trois jours de congé d’urgence personnelle payés. Ces jours de congé permettent entre autres d’aider les familles à prendre des dispositions pour la garde d’enfants durant la fermeture des écoles ou encore pour prendre soin de personnes malades ou vulnérables durant l’épidémie de coronavirus (COVID-19). </li>
            <li>Nous avons mis en place le maintien du salaire dans les cas d’isolement volontaire en raison du coronavirus (COVID-19). </li>
            <li>Nous avons mis sur pied une équipe de direction dédiée à cet enjeu de société 24 heures par jour, 7 jours par semaine et qui fournit aux employés une mise à jour quotidienne de la situation. </li>
          </ul>
        </div>
      </div>
      <div className="flex-container pad-both partners">
        <div className="half">
          <h3>Partenaires et sociétés affiliées</h3>
          <p>Notre compagnie mère, nos partenaires et nos sociétés affiliées mettent en œuvre actuellement de nouvelles mesures pour accroître la sécurité et veiller au bien-être de nos employés, de notre entourage et de nos collectivités. Cliquez sur les logos pour en savoir plus. </p>
        </div>
        <div className="half flex-container">
          <a href="https://jdirving.com/" rel="noopener noreferrer" target="_blank"><img src={Logo1} alt="Logo" /></a>
          <a href="https://www.midlandtransport.com/Home.aspx?LangType=1036" rel="noopener noreferrer" target="_blank"><img src={Logo2} alt="Logo" /></a>
          <a href="https://cavendishfarms.com/fr/?noGeoCheck=true" rel="noopener noreferrer" target="_blank"><img src={Logo3} alt="Logo" /></a>
        </div>s
      </div>

      <CovidDonation />*/}

      <RenderBlock blocks={blocks} />

      <EventTracker gtag={{'allow_custom_scripts': true,'send_to': 'DC-8521957/invmedia/tiger0+standard'}} />
      <img height="1" width="1" style={{"border-style":"none"}} alt="" src="https://insight.adsrvr.org/track/pxl/?adv=7c99unf&ct=0:14yinok&fmt=3" />
      <Offers />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
query {
  defaultPage(guid: { eq:"5c1c9570-0086-4a6f-ac11-7460dc6481ac"}, locale: {eq:"fr"}) {
    title
    title_html
    subtitle
    description
    image_url
    blocks {
      __typename
      ...textBlockFragment
      ...imageLeftTextRightBlockFragment
      ...imageRightTextLeftBlockFragment
      ...videoBlockFragment
      ...imageBlockFragment
      ...threeColumnWithIconsBlockFragment
    }
    urls {
      en
      fr
      zh_hant
      zh_hans
    }
    meta {
      description
      keywords
      title
    }
  }
}
`

export default AboutRoyale
