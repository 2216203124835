/* eslint-disable */
import React, {Component} from 'react';
import styled from '@emotion/styled';
import { Flex, Box } from '../Grid'
import Container from '../Container'
import {FormattedMessage} from "react-intl";

import Chandler from '../../../assets/images/chandler-logo.jpg'
import FeedNS from '../../../assets/images/feed-ns.jpg'
import Moisson from '../../../assets/images/moisson-montreal.jpg'

const BlogText = styled.div`
    font-family: "Raleway","serif";
    font-size: 1.125rem;
    color: #4f5354;
    line-height: 2;
    margin-bottom: 2.5rem;
`

const BlogImage = styled.div`
  background: url(${props => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 300px;
`

const CovidDonation = (props) => {
  return (
    <React.Fragment>
        <Container>
            <Flex
              flexWrap="wrap"
              mx={[
                0,
                -2,
                -2
              ]}
              mb={4}
            >
              <Box
                width={[
                  1,
                  1 / 3,
                  1 / 3
                ]}
                px={[
                  0,
                  2,
                  2
                ]}
                mb={[
                  3,
                  0,
                  0
                ]}
              >
                  <BlogImage
                    src={FeedNS}
                    alt=''
                  />
                  <BlogText>
                    <FormattedMessage id='donation_feedns'>
                      {(txt) => (
                        <p dangerouslySetInnerHTML={{__html: txt}}/>
                      )}
                    </FormattedMessage>
                  </BlogText>
              </Box>
              <Box
                width={[
                  1,
                  1 / 3,
                  1 / 3
                ]}
                px={[
                  0,
                  2,
                  2
                ]}
                mb={[
                  3,
                  0,
                  0
                ]}
              >
                <BlogImage
                  src={Chandler}
                  alt=''
                />
                <BlogText>
                  <FormattedMessage id='donation_chandler'>
                    {(txt) => (
                      <p dangerouslySetInnerHTML={{__html: txt}}/>
                    )}
                  </FormattedMessage>
                </BlogText>
              </Box>
              <Box
                width={[
                  1,
                  1 / 3,
                  1 / 3
                ]}
                px={[
                  0,
                  2,
                  2
                ]}
                mb={[
                  3,
                  0,
                  0
                ]}
              >
                <BlogImage
                  src={Moisson}
                  alt=''
                />
                <BlogText>
                  <FormattedMessage id='donation_moisson'>
                    {(txt) => (
                      <p dangerouslySetInnerHTML={{__html: txt}}/>
                    )}
                  </FormattedMessage>
                </BlogText>
              </Box>
            </Flex>
        </Container>
    </React.Fragment>
  )
}

export default CovidDonation
